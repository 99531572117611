import { createContext, useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { normalizeQueryString } from './query-string';

const IS_SERVER = typeof window === 'undefined';

const initialState = (!IS_SERVER && JSON.parse(localStorage.getItem('adData'))) || {};

const AdDataContext = createContext({ state: initialState });

const AdDataProvider = ({ children }) => {
  const [adData, setAdData] = useState(initialState);

  useEffect(() => {
    localStorage.setItem('adData', JSON.stringify(adData));
  }, [adData]);

  return <AdDataContext.Provider value={{ adData, setAdData }}>{children}</AdDataContext.Provider>;
};

const useAdData = () => {
  const context = useContext(AdDataContext);
  if (context === undefined) {
    throw new Error('useAdData must be used within an AdDataProvider');
  }

  return context;
};

const useQueryAdData = () => {
  const router = useRouter();
  const { adData, setAdData } = useAdData();
  useEffect(() => {
    if (!router.isReady) return;
    const normalizedQuery = normalizeQueryString(router.query);
    const {
      sourceadid: sourceAdId,
      utm_source,
      utm_medium,
      utm_campaign,
      utm_term,
      utm_content,
      gclid,
      kl_pr_id,
    } = normalizedQuery;

    const selectedQuery = {
      sourceAdId,
      utm_source,
      utm_medium,
      utm_campaign,
      utm_term,
      utm_content,
      gclid,
      kl_pr_id,
    };
    const patchAdData = {};
    for (const key in selectedQuery) {
      const value = selectedQuery[key];
      // Do not update unless query string exist and is different
      if (value && value !== adData[key]) {
        patchAdData[key] = value;
      }
    }

    if (Object.keys(patchAdData).length > 0) {
      setAdData({ ...adData, ...patchAdData });
    }
  }, [router, adData, setAdData]);
};

export { AdDataProvider, useAdData, useQueryAdData };
