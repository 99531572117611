// JSON.stringify replacer to serialize errors
function replaceErrors(key, value) {
  if (value instanceof Error) {
    return Object.getOwnPropertyNames(value).reduce(
      (acc, propName) => ({ ...acc, [propName]: value[propName] }),
      {}
    );
  }

  return value;
}

// Sends an error to our vercel log ingest project to be included in GCP logging
export async function reportError(error, metadata = {}) {
  if (!process.env.NEXT_PUBLIC_ERROR_REPORT_URL) {
    return console.warn('Skipping error report for missing NEXT_PUBLIC_ERROR_REPORT_URL');
  }

  try {
    await fetch(process.env.NEXT_PUBLIC_ERROR_REPORT_URL, {
      method: 'POST',
      body: JSON.stringify(
        {
          err: error,
          metadata: {
            ...metadata,
            url: window.location.href,
          },
        },
        replaceErrors
      ),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.NEXT_PUBLIC_ERROR_REPORT_TOKEN}`,
      },
    });
  } catch (err) {
    console.error(err);
  }
}
