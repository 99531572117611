import { Component } from 'react';
import PropTypes from 'prop-types';
import { reportError } from 'utils/report-error';

const propTypes = {
  reportUrl: PropTypes.string,
  children: PropTypes.node.isRequired,
};

const defaultProps = {
  reportUrl: null,
};

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    reportError(error, info);

    // TODO: Remove this throw to have this component render a custom error page.
    throw error;
  }

  render() {
    if (!this.state.hasError) return this.props.children;

    // TODO: Custom error page goes here (or pass it in as a prop and use it here)
    return (
      <div>
        <h2>Oops, there was an error</h2>
      </div>
    );
  }
}

ErrorBoundary.propTypes = propTypes;
ErrorBoundary.defaultProps = defaultProps;

export default ErrorBoundary;
