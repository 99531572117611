import { PHASE_PRODUCTION_BUILD } from 'next/constants';
import Bugsnag from '@bugsnag/js';

let client = {
  notify: (e, ...rest) => {
    console.log('NOTIFY', e, rest);
  },
  leaveBreadcrumb: (e, ...rest) => {
    console.log('LEAVE BREADCRUMB', e, rest);
  },
  setUser(...rest) {
    console.log('SET USER', rest);
  },
};

const apiKey = process.env.NEXT_PUBLIC_BUGSNAG_API_KEY;
const appVersion = process.env.NEXT_BUILD_ID;
const shouldLoadBugsnag = !!apiKey && process.env.NEXT_PHASE !== PHASE_PRODUCTION_BUILD;
const releaseStage = process.env.NEXT_APP_ENV;
const enabledReleaseStages = ['staging', 'production'];

export function start() {
  // Next.js executes top-level code at build time, so use NEXT_PHASE to avoid Bugsnag.start being executed during the build phase
  if (!shouldLoadBugsnag) return;

  Bugsnag.start({
    apiKey,
    appVersion,
    releaseStage,
    enabledReleaseStages,
    plugins: [],
  });
}

if (shouldLoadBugsnag) {
  client = Bugsnag;
}

export default client;
