import { createContext, useContext, useEffect, useState } from 'react';
import { nanoid } from 'nanoid';

const OrderDataContext = createContext();

const IS_SERVER = typeof window === 'undefined';

let storedState;

if (!IS_SERVER) {
  try {
    const stored = sessionStorage.getItem('orderData');
    const buff = Buffer.from(stored, 'base64');
    storedState = stored && JSON.parse(buff.toString('utf8'));
  } catch {
    // If session value fails to parse we will use default
  }
}

const initialState = storedState || {
  orderId: null,
  authKey: null,
  customerData: null,
  paymentProcessor: null,
  paymentMethod: null,
  sessionToken: null,
};

const OrderDataProvider = ({ children }) => {
  const [orderId, setOrderId] = useState(initialState?.orderId);
  const [authKey, setAuthKey] = useState(initialState?.authKey);
  const [customerData, setCustomerData] = useState(initialState?.customerData);
  const [paymentProcessor, setPaymentProcessor] = useState(initialState?.paymentProcessor);
  const [paymentMethod, setPaymentMethod] = useState(initialState?.paymentMethod);
  const [sessionToken] = useState(initialState?.sessionToken ?? nanoid());

  useEffect(() => {
    const data = JSON.stringify({
      orderId,
      authKey,
      paymentProcessor,
      paymentMethod,
      customerData,
      sessionToken,
    });
    const buff = Buffer.from(data);
    sessionStorage.setItem('orderData', buff.toString('base64'));
  }, [orderId, authKey, paymentProcessor, paymentMethod, customerData, sessionToken]);

  return (
    <OrderDataContext.Provider
      value={{
        orderId,
        setOrderId,
        authKey,
        setAuthKey,
        paymentProcessor,
        setPaymentProcessor,
        paymentMethod,
        setPaymentMethod,
        customerData,
        setCustomerData,
        sessionToken,
      }}
    >
      {children}
    </OrderDataContext.Provider>
  );
};

const useOrderData = () => {
  const context = useContext(OrderDataContext);
  if (context === undefined) {
    throw new Error('useOrderData must be used within an OrderDataProvider');
  }

  return context;
};

export { OrderDataProvider, useOrderData };
