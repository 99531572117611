import ErrorBoundary from 'generic/ErrorBoundary';
import Script from 'next/script';
import Head from 'next/head';
import { AdDataProvider } from 'utils/ad-data-context';
import { OrderDataProvider } from 'utils/order-data-context';
import { reportError } from 'utils/report-error';
import { start } from 'utils/bugsnag';

import './styles/contentModule.css';
import './styles/errorPage.css';

start();

const IS_SERVER = typeof window === 'undefined';
if (!IS_SERVER) {
  window.addEventListener('unhandledrejection', event => reportError(event.reason));
  window.addEventListener('error', event => reportError(event.error));
}

function App({ Component, pageProps, err }) {
  const {
    rootDomain,
    googleOptimizeId,
    googleTagManagerId,
    loadFromServerContainer,
    googleTagManagerAuthCode,
    googleTagManagerPreview,
  } = pageProps.funnelData || {};

  const tagDomain = loadFromServerContainer ? `collect.${rootDomain}` : 'www.googletagmanager.com';

  const renderGoogleTagManagerScript = () => (
    <Script id="google-tag-manager" strategy="afterInteractive">
      {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://${tagDomain}/gtm.js?id='+i+dl${
        googleTagManagerAuthCode && googleTagManagerPreview
          ? `+'&gtm_auth=${googleTagManagerAuthCode}&gtm_preview=${googleTagManagerPreview}&gtm_cookies_win=x'`
          : ''
      };f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${googleTagManagerId}');`}
    </Script>
  );

  return (
    <ErrorBoundary>
      <Head>
        <link rel="preconnect" href="https://www.googletagmanager.com" crossOrigin="anonymous" />
        <link rel="dns-prefetch" href="https://www.googletagmanager.com" />

        <link rel="dns-prefetch" href="//www.googleoptimize.com" />
        <link rel="dns-prefetch" href="//connect.facebook.net" />
        <link rel="dns-prefetch" href="https://js.stripe.com" />
        <link rel="dns-prefetch" href="https://api.rudderlabs.com" />
        <link rel="dns-prefetch" href="https://cdn.rudderlabs.com" />

        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
      </Head>
      <AdDataProvider>
        {!!rootDomain && (
          <>
            {process.env.NEXT_PUBLIC_DISABLE_GOOGLE_OPTIMIZE !== 'true' && googleOptimizeId && (
              <Script
                id="google-optimize"
                src={`https://www.googleoptimize.com/optimize.js?id=${googleOptimizeId}`}
              />
            )}

            {/* Google Tag Manager */}
            {process.env.NEXT_PUBLIC_DISABLE_GOOGLE_TAG_MANAGER !== 'true' &&
              renderGoogleTagManagerScript()}
          </>
        )}
        {/* Rudderstack */}
        {process.env.NEXT_PUBLIC_RUDDERSTACK_FEATURE_FLAG &&
          process.env.NEXT_PUBLIC_RUDDERSTACK_WRITE_KEY && (
            <Script id="rudderstack">
              {`
              !function(){var e=window.rudderanalytics=window.rudderanalytics||[];e.methods=["load","page","track","identify","alias","group","ready","reset","getAnonymousId","setAnonymousId","getUserId","getUserTraits","getGroupId","getGroupTraits","startSession","endSession","getSessionId"],e.factory=function(t){return function(){e.push?.([t].concat(Array.prototype.slice.call(arguments)))}};for(var t=0;t<e.methods.length;t++){var r=e.methods[t];e[r]=e.factory(r)}e.loadJS=function(e,t){var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.rudderlabs.com/v1.1/rudder-analytics.min.js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a)},e.loadJS(),
              e.load("${process.env.NEXT_PUBLIC_RUDDERSTACK_WRITE_KEY}", "${process.env.NEXT_PUBLIC_RUDDERSTACK_DOMAIN}")}();
            `}
            </Script>
          )}
        <OrderDataProvider>
          <Component {...pageProps} err={err} />
        </OrderDataProvider>
      </AdDataProvider>
    </ErrorBoundary>
  );
}

export default App;
